import React from "react";
import { FormattedMessage, FormattedHTMLMessage,injectIntl, Link } from "gatsby-plugin-intl"


import Tags from "./tags";
import PostInformation from "./postInformation";
//import TwFrame from "../components/Style/TwFrame"

import styles from "./posts.module.css";

const Post = ({ post, defaultAuthor,intl }) => {
	const renderTags = () => {
		return post.frontmatter.tags ? (
			<Tags tags={post.frontmatter.tags} />
		) : (
			<div></div>
		);
	};

	return (

		<>
			
			
				<hr />
				<Link to={post.fields.path} className="tw-text-xl tw-font-bold tw-text-true-gray-800">
					<h3>{post.frontmatter.title}</h3>
				</Link>
				<hr />
				<PostInformation
					date={post.frontmatter.date}
					timeToRead={post.timeToRead}
					authorInfo={post.frontmatter}
					defaultAuthorInfo={defaultAuthor}
				/>
				<p>{post.excerpt}</p>
				{renderTags()}
			
			
		</>
		
	);
};

const Posts = ({ posts, defaultAuthor }) => {
	return (
		<div class="tw-flex tw-flex-wrap -tw-mx-4">	
			{posts.map((postNode) => (
				<div class="tw-w-full lg:tw-w-1/2 xl:tw-w-1/3 tw-px-4 tw-mb-8 lg:tw-mb-0">
				<Post
					post={postNode.node}
					key={postNode.node.id}
					defaultAuthor={defaultAuthor}
					
				/>	
				</div>			
			))}
		</div>
	);
};

export default injectIntl(Posts);